<script>
import { api } from "@/state/services";

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      banners: null,
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    getBanners() {
      api
        .get('banners')
        .then((response) => {
          this.banners = response.data.list
        })
    }
  },
  mounted() {
    this.getBanners()
  },
  computed: {
    monitorWidth: function () {
      var width = screen.width;
      return width;
    }
  }
}
</script>

<template>
  <div id="banners" v-if="banners && banners.length > 0" class="mb-4">
    <b-carousel id="carousel" class="rounded" v-model="slide" :interval="4000" controls indicators img-height="350" style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
      <b-carousel-slide v-for="banner in banners" :key="banner.index" class="rounded">
        <template v-slot:img>
          <div v-if="monitorWidth < 600">
            <template v-if="banner.url">
              <a :href="banner.url"><img class="w-100 rounded" :src="banner.image.mobile" :alt="banner.title" /></a>
            </template>
            <template v-else>
              <img class="w-100 rounded" :src="banner.image.mobile" :alt="banner.title" />
            </template>
          </div>
          <div v-else>
            <template v-if="banner.url">
              <a :href="banner.url"><img class="w-100 rounded" :src="banner.image.desktop" :alt="banner.title" /></a>
            </template>
            <template v-else>
              <img class="w-100 rounded" :src="banner.image.desktop" :alt="banner.title" />
            </template>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<style scoped>
#banners {
  text-align: center;
}
#banners img {
  object-fit: cover;
  aspect-ratio: 16/5;
}
@media (max-width: 600px) {
  #banners img {
    aspect-ratio: auto;
  }
}
</style>